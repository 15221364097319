import React from 'react'
import { Box, Heading } from 'theme-ui'
import PoweredByGatsby from '@components/PoweredByGatsby'
import { HeaderLogo } from '../Header/Header.Logo'

const styles = {
  logo: {
    pb: 1,
    mb: 0,
    mt: [4, 0]
  },
  copyright: {
    pt: 2,
    mb: [2, 4]
  },
  name: {
    textAlign: ['center', 'left'],
    mb: 0
  }
}

export const FooterLogo = () => (
  <>
    <Box sx={styles.logo}>
      {/* <HeaderLogo grayscale /> */}
      <Heading variant='h4' sx={styles.name}>
        Matheo Dodi
      </Heading>
    </Box>
    <Box sx={styles.copyright}>
      © {new Date().getFullYear()}, All Rights Reserved.
    </Box>
  </>
)
